<template>
    <div>
        <br>
        <h3 class="text-dark">Users</h3>
        <br>
        <my-box>
            <b-button size="sm" variant="primary" class="mr-2" squared :to="'create-user'">Create</b-button>
        </my-box>
        <br>
        <div v-if="response.users.length > 0">
            <my-box>
                <my-table borderless striped :items="response.users" :fields="table.fields" @search="loadUsers" show-btn-ref>
                    <template v-slot:cell(active)="data">
                        <span :class="data.item.active == 'Active' ? 'text-success' : 'text-danger'">{{data.item.active}}</span>
                    </template>
                    <template v-slot:cell(visible)="data">
                        <span :class="data.item.visible == 'Visible' ? 'text-success' : 'text-danger'">{{data.item.visible}}</span>
                    </template>
                </my-table>
            </my-box>
        </div>
        <div v-else>
            <my-loader title="Failed to load" :error-msg="errorMsg" :has-loaded="hasLoaded">
                <b-link variant="link" @click="() => { hasLoaded=false;loadRoles(); }">Try Again</b-link>
            </my-loader>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorMsg: null,
            hasLoaded: false,
            response: {
                users: []
            },
            table: {
                fields: [
                    {
                        key: 'firstname',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'lastname',
                        sortable: true,
                        filter: 'like'
                    },
                    {
                        key: 'visible',
                        sortable: true,
                        filter: 'equal'
                    },
                    {
                        key: 'active',
                        sortable: true,
                        filter: 'equal'
                    },
                    {
                        key: 'activities',
                        sortable: false,
                        label: '',
                    }
                ],
            },
        };
    },
    methods: {

        /**
         * Fetch user data
         */
        loadUsers(filter) {
            this.$store.dispatch("credentials/user/get", { params: filter })
            .then(res => {
                this.response.users = res.data.route 
            })
            .catch(err => {
                this.errorMsg = error.status + ' - ' + error.statusText
            })
        }
    },
    created() {
        this.loadUsers()
    },
};
</script>